/* For Navigation Bar */
html {
    scroll-behavior: smooth;
    transition: all 500ms ease;
    /* background-color: black !important; */
}

body {
    margin: 0;
}

* {
}

/* For the Overview */
.title {
    display: block;
    font-size: clamp(35px, 4.5em, 5.3vw);
    font-weight: bold;
    line-height: 1;
    color: white;
    margin-bottom: 0px !important;
}

.slogan1 {
    display: block;
    color: white;
    font-size: 19px;
    line-height: 1.6;
    --animate-delay: 3.9s !important;
}

.slogan1.top {
    font-weight: 700 !important;
}

.slogan2 {
    color: white;
    font-size: 150%;
}

.hero_home {
    position: relative;
    /* margin-top: 85px !important; */
    margin-bottom: 60px;
    background: url(../../../public/assets/hero_img.jpeg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: -1px 15px 10px -12px rgb(32, 32, 32);
    width: 100%;
    min-height: 110vh;
    /* padding: 0 100px; */
    margin: 0 auto;
}

.hero_home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(2, 2, 7, 0.50);
}

.inner {
    vertical-align: middle;
    width: 30em;
    max-width: 100%;
    /* transform: translateY(-30px); */
}

@media screen and (max-width: 800px) {
    .hero_home {
        position: relative;
        min-height: 103vh;
    }
    .title {
        font-size: 3em;
        line-height: 1.3;
    }
}

@media screen and (max-width: 450px) {
    .outer {
        transform: translateY(-50px);
    }

    .title {
        font-size: 2.3em;
    }

    .slogan1 {
        font-size: 17px !important;
    }
}

@media screen and (max-width: 350px) {
    .title {
        font-size: 2.3em;
    }
}

.second_segment h1 {
    /* font-size: 60px; */
    font-size: clamp(35px, 4em, 5.3vw);
    font-weight: 700;
    /* line-height: 70px; */
    letter-spacing: 0em;
    text-align: left;
}

.second_segment h1 div {
    color: #9CA3AF;
}

.second_segment_top {
    width: 28em;
    max-width: 100%;
}
.second_segment_top p {
    font-size: 19px;
}

.second_segment_card {
    /* min-height: 25em; */
    position: relative;
    border-radius: 15px;
    border: none !important;
    transition: .3s ease-out;
    cursor: pointer;
}
.second_segment_card::before {
    content: '';
    position: absolute;
    top: 0;
    /* left: 0; */
    right: 0;
    /* bottom: 0; */
    width: 0%;
    height: 100%;
    border-radius: 15px;
    background: #DBEAFE;
    transition: .3s ease-out;
}
.second_segment_card:hover::before {
    left: 0;
    width: 100%;
}

.second_segment_card h2 {
font-size: 32px;
font-weight: 700;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 1em;
}

.second_segment_card h2 div {
    color: #9CA3AF;
}
.second_segment_card p {
font-size: 20px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: left;

}

.second_segment_next {
    width: 40em;
    max-width: 100%;
}

.transfers_text_wrap {
    width: 25em;
    max-width: 100%;
}

.transfers_text_wrap h3 {
font-size: 2em;
font-weight: 700;
line-height: 42px;
letter-spacing: -0.14399999380111694px;
text-align: left;
}
.transfers_text_wrap p {
    color: #333333;
font-size: 20px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: left;

}

@media screen and (max-width: 576px) {
    .transfers_text_wrap h3 {
        font-size: 1.4em;
        font-weight: 700;
        line-height: 1.5;
        text-align: left;
        }
}

.image_segment {
    position: relative;
    /* margin-top: 85px !important; */
    background: url(../../../public/assets/intersect_img.jpeg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: -1px 15px 10px -12px rgb(32, 32, 32);
    width: 100%;
    min-height: 100vh;
    /* padding: 0 100px; */
    margin: 0 auto;
}

.image_segment_img {
    display: none;
}

@media screen and (max-width: 950px) {
.image_segment {
    display: none;
}
.image_segment_img {
    display: block;
}
}

.third_segment h1 {
    /* font-size: 60px; */
    font-size: clamp(35px, 4em, 5.3vw);

    font-weight: 700;
    /* line-height: 70px; */
    letter-spacing: 0em;
    text-align: left;
}

.third_segment h1 div {
    color: #9CA3AF;
}

.third_segment_top {
    width: 62em;
    max-width: 100%;
    margin: auto;
}


.second_segment_card.below:hover::before {
    right: 0;
    width: 0%;
}

.last_segment h1 {
    /* font-size: 60px; */
    font-size: clamp(35px, 4em, 5.3vw) !important;

    font-weight: 700;
    /* line-height: 70px; */
    letter-spacing: 0em;
    text-align: left;
}

.last_segment h1 div {
    color: #9CA3AF;
    /* font-size: clamp(35px, 4em, 5.3vw); */
}

.last_segment_top {
    width: 35em;
    max-width: 100%;
}
.last_segment_top p {
    font-size: 19px;
}

.last_segment_btn {
    width: 35em;
    max-width: 100%;
}
.last_segment_btn a {
    background: #111827;
    color: #fff;
    padding: 12px 20px;
    border-radius: 6px;
    text-decoration: none;
    transition: .3s ease;
}

.last_segment_btn a:hover {
    background: #9CA3AF;
    color: #111827;
}

/* For Footer */
.footer_section ul {
    margin: 0px;
    padding: 0px;
}

.footer_section {
    background: #22225b;
    position: relative;
    padding: 0 30px;
}

.footer_cta {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}

.single_cta {
    width: fit-content;
    margin: auto;
    text-decoration: none !important;
}

.single_cta i {
    color: #fff;
    font-size: 30px;
    float: left;
    margin-top: 0px;
}

.cta_text {
    padding-left: 15px;
    display: inline-block;
}

.cta_text h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
}

.cta_text span {
    color: #fff;
    font-size: 15px;
}

.footer_content {
    position: relative;
    z-index: 2;
}

.footer_pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
}

.footer_logo {
    margin-bottom: 30px;
}

.footer_logo img {
    max-width: 200px;
}

.footer_text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 28px;
}

.footer_social_icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.footer_social_icon a {
    color: #667085;
    font-size: 24px;
}

.footer_social_icon i {
    min-height: 40px;
    min-width: 40px;
    display: block;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
    transition: .3s ease;
    margin-right: 15px;
}

.footer_social_icon a:hover i {
    position: relative;
    transform: translateY(-5px) !important;
    box-shadow: 0 0 0 1px #fff;
}

.footer_social_icon a:nth-of-type(3):hover {
    color: #3B5998;
}

.footer_social_icon a:nth-of-type(1):hover {
    color: #55ACEE;
}

.footer_social_icon a:nth-of-type(2):hover {
    color: #eee;
}

.footer_widget_heading h3 {
    color: #667085;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    position: relative;
}

.footer_widget ul li {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    transition: .3s ease;
}

.footer_widget ul li:hover {
    transform: translateX(5px);
}

.footer_widget ul li {
    font-size: 14px;
}

.footer_widget ul li a:hover {
    color: #667085;
}

.footer_widget ul li a {
    text-decoration: none;
    color: #fff;
    text-transform: capitalize;
    transition: .3s ease;
}

.subscribe_form {
    position: relative;
    overflow: hidden;
}

.subscribe_form input {
    width: 100%;
    padding: 14px 75px 14px 12px;
    background: #fff;
    border: none;
    color: #22225b;
}

.subscribe_form input:focus {
    outline: none;
    border: none;
}

.subscribe_form button {
    position: absolute;
    right: 0;
    background: #1e3a8a;
    padding: 13px 20px;
    border: 1px solid #1e3a8a;
    top: 0;
}

.subscribe_form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
}

.copyright_area {
    background: #22225b;
    padding: 25px 0;
}

.copyright_text p {
    margin: 0;
    font-size: 14px;
    color: #667085;
}

.copyright_text p a {
    color: #ff5e14;
}

.footer_menu li {
    display: inline-block;
    margin-left: 20px;
}

.footer_menu li:hover a {
    color: #fff;
}

.footer_menu li a {
    font-size: 14px;
    color: #667085;
    text-decoration: none;
}





/* Country Select Styles */

.custom_select {
    position: relative;
    width: 150px !important;
    overflow: visible;
}

.custom_select::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    background-color: transparent;
    border-radius: 0 8px 8px 0;
    height: 100%;
    transform: translateX(1px);
    cursor: pointer;
}

.custom_select select {
    /* visibility: hidden;
            opacity: 0;
            transition: .5s ease; */
    display: none;
    transition: 0.5s ease;
    /*hide original SELECT element:*/
}

.select_selected {
    background-color: white;
    /* border: 1px solid red !important; */
    border-radius: 8px;
    line-height: 1.3;
    height: auto;
    margin-top: 16px;
    font-size: 15px;
    padding: 10px 35px 10px 10px;
    white-space: nowrap;
    word-break: keep-all;
    word-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
}

.custom_select.change_bg 
.select_selected {
    background-color: #1D1D1F;
    color: white;
}

/*style the arrow inside the select element:*/
.select_selected:after {
    position: absolute;
    content: "";
    top: 18px;
    right: 10px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #1D1D1F transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select_selected.select_arrow_active:after {
    border-color: transparent transparent #1D1D1F transparent;
    top: 10px;
}
.custom_select.change_bg 
.select_selected:after {
    border-color: #fff transparent transparent transparent;
}
.custom_select.change_bg 
.select_selected.select_arrow_active:after {
    border-color: transparent transparent #fff transparent;
}

/*style the items (options), including the selected item:*/

.select_items div {
    display: block;
    margin: auto 4px;
    padding: 8px 6px;
}

.select_items div,
.select_selected {
    color: #000;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
}

/*style items (options):*/
.select_items {
    position: absolute;
    height: auto;
    line-height: 1;
    font-size: 15px;
    background-color: white;
    top: 100%;
    left: auto;
    z-index: 99;
    display: inline-block;
    right: 0 !important;
    width: 200px !important;
    /* height: 20em !important; */
    overflow-x: auto;
    padding: 10px 6px;
    border-radius: 5px;
    border: #f1f1f1 1px solid;
}

/*hide the items when the select box is closed:*/
.select_hide {
    /* display: none; */
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease;
}

.select_items div {
    /* border-radius: 5px; */
}

.select_items div:hover,
.same_as_selected {
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: #1D1D1F;
    color: #fff !important;
    border-radius: 5px;
}

.custom_select.change_bg 
.select_items div:hover,
.same_as_selected {
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: rgb(245, 245, 247);
    color: #1D1D1F !important;
}

/* @media (max-width: 576px) {
    .custom_select {
        position: relative;
        width: 100% !important;
        overflow: visible;
        padding-left: 0 !important;
        margin-left: 0 !important;
        transform: translateX(-6px);
    }

    .select_selected {
        background-color: transparent;
        color: white;
        padding-left: 12px !important;
    }

    .custom_select::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        background-color: #22225b;
        border-radius: 0 8px 8px 0;
        border: 1px solid transparent;
        height: 95%;
        cursor: pointer;
        display: none !important;
    }

    .select_selected:after {
        position: absolute;
        content: "";
        top: 18px;
        right: 30px;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-color: #fff transparent transparent transparent;
    }
} */

/* @media screen and (max-width: 576px) {
    .select_items {
        position: relative;
        top: 0 !important;
        height: auto;
        line-height: 2;
        font-size: 15px;
        background-color: transparent;
        top: 100%;
        left: auto;
        z-index: 99;
        display: inline-block;
        right: 0 !important;
        width: 100% !important;
        height: 100% !important;
        overflow-x: auto;
        padding: 10px 0px;
        border-radius: 5px;
        border: none !important;
    }

    .select_items.select_hide {
        position: absolute !important;
        height: 0 !important;
    }

    .select_selected {
        padding: 10px 0px 15px;
        border-radius: 0 !important;
    }

    .select_items div,
    .select_selected {
        color: #fff;
        border: 1px solid transparent;
        border-color: transparent transparent transparent;
        cursor: pointer;
        user-select: none;
    }

    .select_selected.select_arrow_active {
        border: 1px solid transparent;
        border-color: transparent transparent white transparent;
    }

    .select_items div {
        padding: 10px 0px !important;
        border-color: transparent transparent rgba(250, 250, 250, 0.2) transparent;
    }
} */




/* ============== Animations Measurements ================= */
.anim01 {
    --animate-duration: 1300ms;
    --animate-delay: 0.9s;
}

.anim02 {
    --animate-duration: 1500ms;
    --animate-delay: 0.9s;
}

.anim03 {
    --animate-duration: 1700ms;
    --animate-delay: 0.9s;
}

.anim04 {
    --animate-duration: 1900ms;
    --animate-delay: 0.9s;
}

.anim05 {
    --animate-duration: 2100ms;
    --animate-delay: 0.9s;
}

.anim06 {
    --animate-duration: 2300ms;
    --animate-delay: 0.9s;
}


@media (max-width: 480px) {

    .footer-col ul,
    ul li,
    ul li a {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media screen and (max-width: 576px) {

    nav {
        overflow: visible;
    }

    nav ul {
        float: none;
    }

    nav li {
        width: 100%;
        display: block;
        margin: 0;
        height: 40px;
        line-height: 40px;
        padding-bottom: 30px;
    }

    nav li a:hover {
        color: #0056b3;
    }

    ._break {
        display: inline-block !important;
    }
}