body {
    background-color: #fff;
  }
  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 1;
    visibility: visible;
    transition: opacity 1s ease-out;
}

.preloader.fade-out {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s ease-out;
}
  #loader {
      display: block;
      position: relative;
      left: 50%;
      top: 50%;
      width: 60px;
      height: 60px;
      margin: -30px 0 0 -30px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: rgba(0, 0, 0, 1);
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
  }
  #loader:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: rgba(107, 114, 128, 1);
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
  }
  #loader:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #dee2e6;
      -webkit-animation: spin 1.5s linear infinite;
      animation: spin 1.5s linear infinite;
  }
  @-webkit-keyframes spin {
      0%   {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  @keyframes spin {
      0%   {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }