*,
html,
body {
  font-family: "Inter", sans-serif !important;
  color: rgba(75, 85, 99, 1);
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  /* font-style: normal; */
  /* font-variation-settings:
    "slnt" 0; */
}

/* For Navigation Bar */
html {
  scroll-behavior: smooth;
  transition: all 500ms ease;
  /* background-color: black !important; */
  overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
small,
span {
  color: rgba(75, 85, 99, 1);
}

.opacity_0 {
  opacity: 0 !important;
  cursor: default !important;
}

a {
  text-decoration: none !important;
}

.text-white {
  color: #fff !important;
}

.btn-primary span,
.btn-primary span i {
  color: #fff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #111827 !important;
}

.no-cursor {
  cursor: no-drop !important;
}
  .cursor-default {
    cursor: default !important;
  }
    .cursor-pointer {
  cursor: pointer;
}

.text-dark {
  color: #000000 !important;
}

small {
  font-weight: 400 !important;
}

small {
  font-weight: 400 !important;
}

.tab_wrapper button[role="tab"] {
  font-weight: 500 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: fit-content !important;
  margin-right: 2em;
}
.tab_inv_wrapper button[role="tab"] {
  font-weight: 500 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  min-width: fit-content !important;
  /* margin-right: .8em; */
}
.tab_trfStats_wrapper button[role="tab"] {
  font-weight: 500 !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-width: fit-content !important;
  font-size: 12px;
  margin-right: 10px;
}
.select_trfStats_wrapper {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  /* overflow: hidden; */
  background: rgba(243, 244, 246, 1);
}
.select_trfStats_wrapper div.MuiFormControl-root {
  position: absolute !important;
}
.select_trfStats_wrapper div.MuiFormControl-root,
.select_trfStats_wrapper div.MuiInputBase-root,
.select_trfStats_wrapper div.MuiFormControl-root input,
.select_trfStats_wrapper div.MuiFormControl-root fieldset
 {
  opacity: 0;
  height: 100%;
  cursor: default !important;
}
  .select_trfStats_wrapper div[role="combobox"] {
    width: 50px;
    height: 33px;
}
.select_trfStats_wrapper .select_trfStats_svg {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #aeaee8; */
}

.main-menu {
  font-weight: 500;
  color: #000;
  font-size: 11px;
}

/* For Side Menu Title */
.menu-title {
  font-size: 14px;
  font-weight: 500;
  margin-left: 13px;
  margin-top: 36px !important;
  margin-bottom: 14px !important;
  color: #000;
}

.css-1aq1xob-MuiButtonBase-root-MuiListItemButton-root {
  margin: 0 !important;
  margin-bottom: 0 !important;
}

.css-19kzrtu {
  padding: 0px !important;
}


.css-13xfq8m-MuiTabPanel-root {
  padding: 4px !important;
}

.MuiPaper-root {
  background-color: rgba(249, 250, 251, 1);
  /*  color: #212B36;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  /* box-shadow: 0px 8px 10px -5px rgba(145, 158, 171, 0.2), 0px 16px 24px 2px rgba(145, 158, 171, 0.14), 0px 6px 30px 5px rgba(145, 158, 171, 0.12); */
  /* box-shadow: none !important; */
}

.file_preview>div {
  overflow: scroll !important;
}

.file_preview {
  width: 100%;
  overflow: auto;
  max-width: 100%;
  max-height: 15em;
  /* margin: auto; */
  /* text-align: center; */
  /* display: -webkit-box; */
  /* -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; */
  font-size: 12px;
  color: #6e798a !important;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* line-height: 2; */

}

.form-control {
  box-shadow: none !important;
  border: 1px solid #000;
}

.input_amt div[role="combobox"] {
  max-width: 80px !important;
  padding: 12.5px 20px 12.5px 15px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-mmf85d {
  padding: 14px !important;
  margin-top: 4px !important;
}

.success_indicator {
  color: rgba(6, 95, 70, 1);
  font-size: 12.59px;
  font-weight: 500;
}

.failed_indicator {
  color: rgb(168, 46, 46);
  font-size: 12.59px;
  font-weight: 500;
}

.remove_padding {
  padding: 4rem 4rem 4rem 3.5rem !important;
}

.table-fonts {
  font-size: 13px;
  color: rgba(75, 85, 99, 1);
}

tbody tr:hover {
  background-color: rgba(232, 235, 238, .7) !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.select_wrap div[role="combobox"] {
  padding-left: 6px !important;
  padding-right: 0 !important;
}

div[role="combobox"] .MuiSvgIcon-root {
  right: 0 !important;
}

path[d="M7 10l5 5 5-5z"] {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

.remove_lines fieldset {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.remove_lines.addons .MuiSvgIcon-root {
  transform: translateX(13px);
}

.selector {

  background-color: #252525;
  color: #fff;
  border: none;
  border: 1px solid #595E57;
  padding: 5px;
  width: 150px;
  overflow:hidden;
}
.selector option{
padding:5px;
}
.selector option:hover{
  background-color: #222 !important;
}

.css-1nmdiq5-menu .css-10wo9uf-option:hover {
  background-color: #222 !important;
  color: #fff !important;
}

.css-10wo9uf-option:hover {
  background-color: #222 !important;
  color: #fff !important;
}

.css-tr4s17-option {
  background-color: #222 !important;
  color: #fff !important;
}
.css-10wo9uf-option::before {
  background-color: #222 !important;
  color: #fff !important;
}

.text-underline {
  cursor: pointer !important;
  text-decoration: underline !important;
}

/* .css-qr46ko {
  background-color: #222 !important;
  color: #fff !important;
} */

.css-10wo9uf-option::after {
  background-color: #222 !important;
  color: #fff !important;
}

.css-1nmdiq5-menu .css-qr46ko .css-10wo9uf-option:hover {
  background-color: #222 !important;
  color: #fff !important;
}

/* .css-1nmdiq5-menu {
  background-color: #000 !important;
  color: #fff !important;
} */

/* .css-1nmdiq5-menu:hover {
  background-color: #000 !important;
  color: #fff !important;
} */

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12.50px !important;
  color: rgba(104, 115, 133, 1);
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.apexcharts_tooltip {
  border-radius: 5px;
  /* box-shadow: 2px 2px 6px -4px #999; */
  cursor: default;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 999;
  transition: .15s ease all;
  width: 12em;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  color: #111827 !important;
}
.apexcharts-grid,
.apexcharts-series path
{
  fill: Gray/300 !important;
}
.apexcharts-line-series path {
  fill: Gray/200 !important;

}

.preview_amount {
  color: rgba(65, 69, 82, 1);
}

.border-primary {
  border-color: #222222 !important;
  color: #222222;
}

.color-primary {
  color: #222222;
}

/* .th_width {
  width: 18% !important;
}

.th_width2 {
  width: 20% !important;
}

.th_width3 {
  width: 28% !important;
}

.th_width4 {
  width: 26% !important;
}

.th_width_12 {
  width: 14% !important;
} */

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .855em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.tab_wrapper button {
  font-weight: 500 !important;
}

.success_indicator {
  color: rgba(6, 95, 70, 1);
  font-size: 12.59px;
  font-weight: 500;
}

.failed_indicator {
  color: rgb(168, 46, 46);
  font-size: 12.59px;
  font-weight: 500;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.855em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.btn {
  box-shadow: none !important;
  padding: 6px 12px;
  border-radius: 5px !important;
  font-weight: 500;
  font-size: 13px !important;
}

.text-primary,
.page-link {
  color: #111827 !important;
}

.page-item,
.page-link {
  box-shadow: none !important;
}

.page-item.active .page-link {
  background-color: rgba(238, 242, 255, 1) !important;
  color: #111827 !important;
  border: 1px solid rgba(107, 114, 128, 1) !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #111827 !important;
  border: none !important;
  /* padding: 8px 18px; */
  border-radius: 5px !important;
}

.btn.pginate small {
  font-weight: 500 !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}

.back_btn {
  position: relative;
  /* padding-left: 25px !important; */
}

.back_btn .chevron_left {
  transition: 0.2s ease;
  /* position: absolute;
  top: 2px;
  left: 10px; */
}

.back_btn:hover .chevron_left {
  transform: translateX(-4px);
}

.dropdown_text {
  font-size: 13px;
  font-weight: 400;
  padding: 0.45rem 1.1rem;
}

.top_ups p {
  color: rgba(104, 115, 133, 1);
}

.button_text {
  font-size: 12px !important;
  font-weight: 500 !important;
  white-space: nowrap;
}

.chart_side_wrap {
  font-size: 14px;
}

.wallet_modal_header span {
  margin: auto;
  max-width: 100%;
  color: rgba(55, 65, 81, 1);
  font-size: 16px;
  font-weight: 500;
  text-align: center;

}

.wallet_modal_details label {
  font-size: 14px;
  font-weight: 500;
  line-height: 31px;
  text-align: left;
  color: rgba(55, 65, 81, 1);
}

.wallet_section_wrap {
  margin-top: 2rem;
}

.wallet_title_color {
  color: rgba(55, 65, 81, 1);
}

.dashed_border {
  border-bottom: 1px dashed lightgray;
}

.amount_text_size {
  font-size: 19px;
  color: rgba(65, 69, 82, 1);
}

header {
  position: absolute;
  padding: 0 0 10px 0;
  top: 0;
  width: 100%;
  z-index: 99;
  color: #000 !important;
}

.nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.logo_box {
  float: left;
  margin-right: 20px;
  margin-top: 8px;
}

.header_img {
  width: 130px;
}

.logo_box a {
  outline: none;
  display: block;
}

.logo_box img {
  display: block;
}

.navbar {
  /* overflow: hidden; */
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2em;
}

.navbar li {
  display: inline-block;
  margin-left: 5px;
  /* height: 60px;
    line-height: 55px; */
  transition: 0.5s linear;
  vertical-align: middle;
  text-wrap: nowrap;
}

nav a.nav-link {
  text-decoration: none;
  position: relative;
  /* font-family: "Roboto", cursive; */
  color: white;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
}

nav a.nav-link:hover {
  color: whitesmoke;
}

nav a.nav-link div {
  display: inline-block;
  width: 16px;
  height: 15px;
  /* border: 1px solid red; */
  position: relative;
  vertical-align: middle;
  margin-left: 5px;
  transition: 0.3s ease;
}

nav a.nav-link div i {
  position: absolute;
  line-height: 0;
  transition: 0.3s ease;
}

li:hover i {
  position: absolute;
  transform: rotateX(180deg);
}

/* 
    nav a:after {
      content: "";
      width: 0;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: -10px;
      background: #ffa600;
      transition: width 0.3s ease-out;
    }
  
    nav a:hover:after {
      width: 100%;
    } */

@media screen and (max-width: 660px) {
  .header_img {
    width: 100px;
  }

  .logo_box {
    display: inline-block;
    margin: 0 0 0px 0;
  }

  ul {
    float: none;
  }

  nav li:first-of-type {
    margin-top: 0px;
  }

  nav {
    padding-bottom: 0px;
  }

  .nav-container {
    padding: 0 20px;
  }

  .picture,
  .right-div {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .right-div {
    max-width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .picture {
    width: 100%;
    height: 200px;
  }
}

.nav_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 2px;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.hamburger .bar:nth-child(2) {
  width: 34px;
}

.positioned_button {
  /* position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%); */
  display: none;
}

.positioned_button>button {
  width: 40px !important;
  height: 40px !important;
  outline: none !important;
  box-shadow: none !important;
  transform: translateY(2px);
}

.dropdown_menu {
  background: whitesmoke;
  /* backdrop-filter: blur(32px); */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: none !important;
  margin-top: 20px;
}

.dropdown_menu a {
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.dropdown_menu button:hover {
  background: rgba(34, 34, 91, 0.1);
  /* color: white; */
}

/* .positioned_button > button:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  } */

@media (max-width: 576px) {
  .navbar ul {
    gap: 1em;
  }

  /* .hamburger {
      display: block;
    }
  
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }
  
    .hamburger.active .bar:nth-child(1) {
      transform: translateY(10px) rotate(45deg);
    }
  
    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-10px) rotate(-45deg);
    }
  
    .nav_menu {
      position: fixed;
      left: -100%;
      top: 80px;
      gap: 0;
      flex-direction: column;
      background-color: #22225b;
      width: 100%;
      text-align: left;
      transition: 0.3s;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
    }
  
    .nav_item {
      margin: 0;
    }
  
    .nav_menu.active {
      left: 0;
      z-index: 999 !important;
    }
  
    header {
      padding: 23px 0 !important;
    } */

  /* .positioned_button {
      display: none !important;
    }
  
    .nav_menu {
      padding-left: 0 !important;
    }
  
    .nav_menu .nav_item {
        width: 100%;
      border-bottom: rgba(250, 250, 250, 0.2) 1px solid;
      margin-left: 0 !important;
      padding-left: 15px !important;
    }
  
    nav ul {
      float: none;
      height: 64vh;
      overflow-y: auto;
    }
  
    .nav_container::before {
      visibility: hidden;
      opacity: 0;
      content: '';
      position: absolute;
      top: 80px;
      left: 0;
      height: 100vh;
      width: 100%;
      transition: .3s ease;
      z-index: 1;
    }
  
    .nav_container.active::before {
      visibility: visible;
      opacity: 1;
    } */
}

@media (max-width: 991px) {
  .invoice_collected_fields {
    flex-wrap: wrap;
  }
}

.react-datepicker-wrapper input,
.MuiInputBase-root input {
  font-size: 13px !important;
}

.hero_home_wrap {
  /* background: url(../public/assets/herobg-video.mp4) no-repeat center; */
  background-size: 100% 100%;
  /*  */
}

/* .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    z-index: -1;
  } */

.hero_about_wrap {
  /* background: url(../public/assets/Launchpad_AboutUs.mp4) no-repeat center; */
  background-size: 100% 100%;
}

.hero_nav_dropdown {
  position: relative;
  display: inline-block;
}

.dropdown_content {
  padding: 18px 30px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  border-radius: 6px;
  background-color: #ffffff;
  width: 17em;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  transform: translateY(15px);
  transition: 0.3s ease;
}

.dropdown_content a {
  line-height: 1.5;
  color: black;
  text-decoration: none;
  text-align: left;
}

.dropdown_content a:hover {
  background-color: #ddd;
}

.hero_nav_dropdown:hover .dropdown_content {
  visibility: visible;
  opacity: 1;
  transform: translateY(-5px);
}

.dropdown_content p {
  text-wrap: wrap;
  font-size: 12px;
  color: #999;
}

.content_link {
  background-color: transparent;
  border-radius: 8px;
  padding: 10px 10px 0;
  cursor: pointer;
}

.content_link:hover {
  background-color: #aeaee8;
  border-radius: 8px;
  color: #fff !important;
}

.content_link h6 {
  font-weight: 700 !important;
}

.content_link:hover p,
.content_link:hover h6 {
  color: #fff !important;
}

.dropdown_content.dev {
  padding: 8px 0px;
  width: 6em;
  overflow: hidden;
}

.dropdown_content.dev .content_link p {
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.dropdown_content.dev .content_link {
  background-color: transparent;
  border-radius: 8px;
  padding: 10px 30px 0;
  cursor: pointer;
}

.dropdown_content.dev .content_link:hover {
  background-color: #aeaee8;
  border-radius: 8px;
  color: #fff !important;
}

.tab_buttons.active {
  font-size: 4.5cqw;
  font-weight: 500;
  transition: color 0.5s ease;
}

.pricing_tabs button.active {
  background-color: #22225b !important;
  color: #fff !important;
}

@media screen and (max-width: 1350px) {
  .nav_container {
    padding: 0 30px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 900px) {
  .dropdown_content {
    border-radius: 0;
  }

  li:hover i {
    position: absolute;
    transform: rotateX(0deg);
  }

  li.active i {
    position: absolute;
    transform: rotateX(180deg);
  }

  .hero_nav_dropdown:hover .dropdown_content {
    visibility: hidden;
    opacity: 0;
  }

  .hero_nav_dropdown.active .dropdown_content {
    visibility: visible;
    opacity: 1;
    transform: translateY(-5px);
  }
}

@media screen and (max-width: 550px) {
  .nav_container {
    padding: 0 30px 0 20px;
    margin: 0 auto;
  }

  .dropdown_content {
    max-width: 92%;
  }

  .dropdown_content.dev {
    padding: 8px 0px;
    width: 100% !important;
    overflow: hidden;
  }

  .dropdown_content.dev .content_link {
    padding: 10px 30px 0;
  }

  .tab_buttons.active {
    font-size: 2em;
    font-weight: 500;
  }

  nav a.nav-link div {
    display: inline-block;
    width: 16px;
    height: 15px;
    /* border: 1px solid red; */
    position: relative;
    vertical-align: middle;
    margin-right: 15px;
    transition: 0.3s ease;
    float: right;
    transform: translateY(20px);
  }

  nav a.nav-link div i {
    position: absolute;
    line-height: 0;
    transition: 0.3s ease;
  }
}

.slide {
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  width: 100%;
  color: white;
  background-size: cover;
  background-position: center;
}

.grid_image_container {
  display: grid;
  grid-gap: 1.8rem;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(12, 150px); */
  margin: 2em auto 0;
  max-width: 100%;
  padding: 3rem 0;
}

.grid_img {
  border-radius: 1rem;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.item-1 {
  grid-row: 1/5;
}

.item-4 {
  grid-row: 2/6;
}

@media screen and (max-width: 900px) {
  nav a.nav-link div {
    display: inline-block;
    width: 16px;
    height: 15px;
    /* border: 1px solid red; */
    position: relative;
    vertical-align: middle;
    margin-right: 15px;
    transition: 0.3s ease;
    float: right;
    transform: translateY(20px);
  }

  nav a.nav-link div i {
    position: absolute;
    line-height: 0;
    transition: 0.3s ease;
  }
}

@media screen and (max-width: 550px) {
  .grid_image_container {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    margin: 0em auto 0;
    max-width: 100%;
    padding: 3rem 0;
  }

  .item-1 {
    grid-row: 1/4;
  }

  .item-4 {
    grid-row: 2/5;
  }
}

.carousel-indicators {
  /* border: 2px solid red; */
  margin: 0 9em 0 !important;
  justify-content: flex-start !important;
}

.carousel-indicators .indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.carousel-indicators .indicator.active {
  background-color: #60a5fa;
}

header.fixed {
  position: fixed !important;
  right: 0;
  left: 0;
  top: -130px;
  background: #ffffff !important;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
}

header.fixed.scrolled {
  background: #ffffff !important;
  visibility: visible;
  opacity: 1;
  top: 0px !important;
  /* margin-top: 0px !important; */
  -webkit-transition: 0.3s all ease-out;
  -o-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}

div.sat_image,
svg.sat_image {
  filter: brightness(0%);
}

.drp_dwn_menu_right {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  border: none !important;
  /* transform: translate3d(-401px, 40px, 0px); */
  background-color: rgba(243, 244, 246, 1) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  width: fit-content !important;
}

.drp_dwn_menu_right div[role="combobox"] {
  background: white;
}

.fit-content {
  width: fit-content;
}


.drp_dwn_menu_right.second {
  transform: translate3d(-270px, 40px, 0px) translateX(-30px) !important;

}

.drp_dwn_menu_right.second2 {
  transform: translate3d(-210px, -10px, 0px) translateX(-30px) !important;

}

.drp_dwn_menu_right.second3 {
  transform: translate3d(-40px, -10px, 0px) translateX(-30px) !important;

}

.drp_dwn_menu_right.second4 {
  transform: translate3d(-170px, -10px, 0px) translateX(-30px) !important;

}

.drp_dwn_menu_right.second5 {
  transform: translate3d(-100px, -10px, 0px) translateX(-30px) !important;

}

.style-dropdown {
  /* transform: translate3d(200px, 20px, 0px) !important; */
}

.navbar_search {
  position: relative;
  /* border: 1px solid red; */
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
}

.navbar_search input {
  padding: 10px;
  font-size: 10px;
  color: #667085;
  display: block;
  width: 100%;
  position: relative;
  border: none;
  outline: none;
  text-indent: 27px;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
}

.navbar_search svg.search {
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translateY(-50%) scale(1.3);

}
.MuiGrid-root .MuiPaper-root {
  background-color: transparent !important;
}

.navbar_search input:focus {
  background: #fff;
  cursor: auto;
}

.navbar_search.right {
  font-size: 13px !important;
  color: rgba(75, 85, 99, 1);
  white-space: nowrap;
  width: fit-content;
}

.page_title {
  font-size: 18px;
  font-weight: 700;
  line-height: 19.36px;
  color: rgba(16, 24, 40, 1);
  margin-left: 0px;
}

.notif_sett {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  /* border: 1px solid lightgray; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notif_sett:hover {
  background: rgba(75, 85, 99, .1);
}

.custom-control.custom-switch input,
.custom-control.custom-switch label,
.custom-control.custom-switch label::before {
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer;
}

label[for="customSwitch1"] {
  cursor: pointer;
}

.custom-control-input~.custom-control-label::before {
  color: #fff;
  border-color: #ccc !important;
  background-color: #ccc;
  /* margin-top: 0px !important; */
  -webkit-transition: 0.3s all ease-out;
  -o-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}

div.sat_image,
svg.sat_image {
  filter: brightness(0%);
}

.drp_dwn_menu_right {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  border: none !important;
  transform: translate3d(-25%, -10px, 0px);
  background-color: #f9fafb;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  width: fit-content !important;
}

.drp_dwn_menu_right label {
  font-weight: 500;
  font-size: 13px !important;
}

::placeholder,
div[role="combobox"] {
  /* font-size: 14px; */
  font-size: 13px !important;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #9CA3AF;
}

.drp_dwn_menu_right div,
.start_enddate input,
div[role="combobox"] *,
.liveselect_noborderradius *,
label~div * {
  border-radius: 0 !important;
  font-size: 13px !important;
}

.select_wrap label~div *:hover,
.select_wrap label~div *:active,
.select_wrap label~div *:focus,
.select_wrap label~div fieldset:hover,
.select_wrap label~div fieldset *:hover,
.select_wrap label~div fieldset *:active,
.select_wrap label~div fieldset *:focus,
.select_wrap label~div input:hover,
.select_wrap label~div input:focus,
.select_wrap label~div input:valid {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.charges_input input {
  min-height: 35px !important;
  padding-left: 8px !important;
}

.select_wrap div[aria-haspopup="listbox"] {
  font-size: 13px !important;

}

.input_with_country_logo .MuiFormControl-root,
.input_with_country_logo .MuiInputBase-root,
.input_with_country_logo input,
.input_with_country_logo fieldset {
  background-color: transparent !important;
}

.input_with_country_logo input {
  /* text-indent: 63px !important; */
}

.css-ata1q1-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-ata1q1-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root .MuiOutlinedInput-notchedOutline,
.select_wrap div,
.select_wrap * {
  /* border: none !important; */
  outline: none !important;
  box-shadow: none !important;
}

.MuiInputBase-root {
  border-radius: 0 !important;
}
.height_40 .MuiInputBase-root {
  min-height: 40px !important;
}
.height_42 .MuiInputBase-root {
  min-height: 42px !important;
  background: #F3F4F6 !important;
}
.MuiStepLabel-iconContainer:not(.Mui-active) svg circle,
.MuiStepLabel-label.Mui-completed,
.MuiStepLabel-iconContainer:not(.Mui-active) .MuiStepLabel-labelContainer span.MuiStepLabel-label
 {
  font-weight: 600;
  fill: #9CA3AF !important;
  -webkit-text-fill-color: #9CA3AF !important;
  cursor: pointer;
}
.MuiStepLabel-label.Mui-active,
.MuiStepLabel-label.Mui-disabled {
  font-weight: 600;
  cursor: pointer;

}
.MuiStepLabel-label.Mui-disabled {
  -webkit-text-fill-color: #9CA3AF !important;
}
  .MuiStepLabel-iconContainer.Mui-active {}
    .MuiStepLabel-iconContainer.Mui-active svg circle {
  fill: #000;
}
.MuiSelect-select[role="combobox"] {
  padding: 8.5px 14px !important;
  background-color: transparent !important;
}

.dropdown_closer i {
  font-size: 20px !important;
}

.navbar_search {
  position: relative;
  /* border: 1px solid red; */
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
}

.navbar_search input {
  padding: 10px;
  font-size: 14px;
  color: #667085;
  display: block;
  width: 100%;
  position: relative;
  border: none;
  outline: none;
  text-indent: 27px;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
}

.navbar_search svg.search {
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translateY(-50%) scale(1.3);
}

.navbar_search input:focus {
  background: #fff;
  cursor: auto;
}

.navbar_search.right {
  font-size: 13px !important;
  color: rgba(75, 85, 99, 1);
  white-space: nowrap;
  width: fit-content;
}

.notif_sett {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  /* border: 1px solid lightgray; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notif_sett:hover {
  background: rgba(75, 85, 99, 0.1);
}

body div[role="presentation"] div.MuiBackdrop-root[aria-hidden="true"] {
  /* background-color: rgba(255, 255, 255, .5) !important; */
  background: rgba(233,235,234, .7) !important;
  /* -webkit-backdrop-filter: blur(5px) !important;
  backdrop-filter: blur(5px) !important; */
}

.settings_option_box {
  cursor: pointer;
  transition: .3s ease;
  padding: 10px;
}

.settings_option_box h6 {
  font-size: 14px;
  font-weight: 500 !important;
  color: #000;
}

.settings_option_box:hover {
  background: #f9fafb;
}

.custom-control {
  width: 35px !important;

}

.custom-control.custom-switch.wallet_sect input {
  border: 1px solid red;
  visibility: visible;
  opacity: 0;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.custom-control.custom-switch input,
.custom-control.custom-switch label,
.custom-control.custom-switch label::before {
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer;
}

label[for="customSwitch1"] {
  cursor: pointer;
}

.custom-control-input~.custom-control-label::before {
  color: #fff;
  border-color: #ccc !important;
  background-color: #ccc;
  width: 35px !important;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #222222 !important;
  background-color: #222222;
}

.custom-control-input.setup_sect:checked~.custom-control-label::before {
  color: #fff;
  border-color: #9CA3AF !important;
  background-color: #9CA3AF;
  opacity: 0.6;
}

.custom-control.default .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #bbb !important;
  background-color: #bbb;
}
.checkbox_div.radio_btn svg {
  fill: #374151 !important;
}

.custom-switch .custom-control-label::after {
  top: calc(.25rem + 2px);
  left: calc(-2.25rem + 3px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(1.1rem);
  transform: translateX(1.1rem);
}

.modal_content::-webkit-scrollbar,
.modal_content::-webkit-scrollbar-track {
  appearance: none;
  display: none;
}

.box_with_dollarsign input {
  text-indent: 20px !important;
}

.box_with_ngnsign input {
  text-indent: 35px !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.custom_short_datepkr>div {
  display: block !important;
}

.custom_short_datepkr input {
  text-indent: 30px;
}

label {
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.text-underlined {
  text-decoration: underline !important;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px !important;
}

.due_date .react-datepicker-wrapper {
  display: block !important;
  width: 100% !important;
  padding: 0;
  border: 0;
}

.edit_trsn_effective_date .react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper .react-datepicker__input-container,
.react-datepicker-wrapper .react-datepicker__input-container input
{
  background: transparent !important;
}

.payment_date .react-datepicker-wrapper input {
  padding-left: 38px;
}

.payment_date .react-datepicker-wrapper {
  width: 100%;
}

/* Styling the dropdown arrow */
input[type="time"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.react-datepicker-popper {
  z-index: 39 !important;
}

.balance_as_at .react-datepicker-wrapper,
.balance_as_at .react-datepicker-wrapper .react-datepicker__input-container,
.balance_as_at .react-datepicker-wrapper .react-datepicker__input-container input {
 background-color: transparent !important;
}
.balance_as_at .react-datepicker-wrapper {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}
.card_expiry .react-datepicker-wrapper * {
  border: none !important;
  outline: none !important; 
}
.card_expiry .react-datepicker-wrapper input {
  border: 1px solid #D1D5DB !important;
}
  .card_expiry .react-datepicker-wrapper {
  width: 100%;
  border: none !important;
  outline: none !important;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.card_expiry .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100% !important;
  height: 40px !important;
  text-align: left;
  font-weight: 500 !important;
  color: transparent !important;
}

/* .balance_as_at .react-datepicker-wrapper,
.balance_as_at .react-datepicker-wrapper .react-datepicker__input-container, */
.balance_as_at .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100% !important;
  height: 30px !important;
  text-align: right;
  font-weight: 500 !important;
}
.userSettings_tab_section .MuiTabs-scroller.MuiTabs-fixed {
  overflow: auto !important;
}
.userSettings_tab_section .MuiTabs-scroller.MuiTabs-fixed::-webkit-scrollbar,
.userSettings_tab_section .MuiTabs-scroller.MuiTabs-fixed::-webkit-scrollbar-track
{
  appearance: none;
  display: none;
}

.userSettings_tab_section .MuiButtonBase-root.MuiTab-root[role="tab"] {
  margin-right: 0 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-weight: 500 !important;
  text-transform: none;
}
/* .react-datepicker__navigation {
  z-index: 99 !important;
} */
input[type="time"]::-webkit-calendar-picker-indicator {
  color: #333;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
  width: 100%;
  text-align: right;
  margin-left: auto;
  cursor: pointer;
  z-index: 1;
}

input[type="time"]::-moz-calendar-picker-indicator {
  color: #333;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
  width: 100%;
  text-align: right;
  margin-left: auto;
  cursor: pointer;
}

input[type="time"]::-ms-calendar-picker-indicator {
  color: #333;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
  width: 100%;
  text-align: right;
  margin-left: auto;
  cursor: pointer;
}

input[type="time"]::-calendar-picker-indicator {
  color: #333;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
  width: 100%;
  text-align: right;
  margin-left: auto;
  cursor: pointer;
}

/* Styling the dropdown list (may not work consistently across all browsers) */
input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

input[type="time"]::-moz-datetime-edit-fields-wrapper {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

input[type="time"]::-ms-datetime-edit-fields-wrapper {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

input[type="time"]::-datetime-edit-fields-wrapper {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.checkbox_div svg,
.checkbox_div path {
  color: #ccc !important;
}

.checkbox_div input:checked~svg,
.checkbox_div input:checked~svg path {
  color: #111827 !important;
}

.checkbox_div span {
  padding: 0 !important;
}


.checkbox_div.light div {
  width: 20px !important;
  height: 20px !important;
}
  .checkbox_div.light .MuiButtonBase-root.MuiCheckbox-root {
    position: absolute;
  width: 100% !important;
  height: 100% !important;
  z-index: 10;
}

.checkbox_div.light svg {
position: absolute !important;
}
.checkbox_div.light input~svg {
  width: 22px !important;
  height: 22px !important;
}


.checkbox_div.light input~svg,
.checkbox_div.light input~svg path {
  opacity: 0;
}

.all_selectdropdown_icon {
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-content-wrapper::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar-track {
  appearance: none;
  display: none;
}


.merged_side_btn a {
  transition: .3s ease;
  /* padding-left: 15px !important;
  padding-left: 5px !important; */
}

.merged_side_btn a:hover {
  background: #F9FAFB !important;
}

.merged_side_btn a:nth-of-type(1) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.merged_side_btn a:nth-of-type(2) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;
}

.styled_tbody {
  height: 400px !important;
  max-height: 400px !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}


.sidenav_initials_wrap .sidenav_initials {
  min-width: 38px;
  min-height: 38px;
  max-width: 38px;
  max-height: 38px;
  border-radius: 50%;
  background-color: #000000;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 13px;

}

.sidenav_initials_wrap p {
  font-size: 14px;
  font-weight: 500;
  text-align: left;

}

.sidenav_initials_wrap span {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.noPath_text {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #4B5563;
  padding: 10px 0 10px 6px;
}

div[role="combobox"] {
  color: #111827 !important;
}

div[tabindex="-1"] ul li {
  font-size: 13px !important;
}

.left_side_select {
  width: 15em;
  max-width: 100%;
}

table .copy_link_btn {
  border-radius: 3px !important;
}

.create_invoice_tab_section .MuiButtonBase-root.MuiTab-root[role="tab"] {
  margin-right: 0 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-weight: 500 !important;
}

/* .MuiTabPanel-root.invoice_preview_wrap {
  padding-left: 30px !important;
  padding-right: 30px !important;
} */

@media screen and (max-width: 768px) {

  .left_side_select {
    width: 100%;
    max-width: 400px;
  }

}

@media screen and (max-width: 576px) {
.drawer_inv_summary_wrap .addOns_sect {
  width: 100% !important;
}

  .page_title {
    margin-bottom: 15px;
  }

  .header_btn_group {
    width: fit-content;
    margin-left: auto;
    margin-bottom: 15px;
  }

  .start_enddate input::placeholder,
  .start_enddate input {
    font-size: 13px !important;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }

  .responsive_paginate_btn_sect .card-body {
    padding: 0 0 20px !important;
  }

  .responsive_paginate_btn_sect .count_wrap,
  .responsive_paginate_btn_sect .pagination_wrap {
    width: fit-content;
    margin: auto;
  }

  .responsive_paginate_btn_sect .card-body button:nth-of-type(1) {
    margin-right: 6px !important;
  }

  /* .responsive_paginate_btn_sect div {
    width: fit-content !important;
    margin: auto !important;
    display: block !important;
    text-align: center !important;

  } */
}


/* DropZone Styles */

.drop_zone {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 12em;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px !important;
  overflow: hidden;
  /* cursor: pointer; */
  background: rgba(234, 236, 240, .5);
}

.drop_zone p {
  text-align: center;
  color: #667085;
}

.drop_zone p span {
  font-weight: 500;
  font-size: 14px !important;
}

.drop_zone input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.change_csv_container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.change_csv_container button {
  background: rgba(234, 236, 240, 1) !important;
  width: 50px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  z-index: 10;
}

.change_csv_container button i {
  line-height: 0;
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.change_csv_wrap {
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(234, 236, 240, 1) !important;
}

.drop_zone .change_csv,
.imgDrop_zone .change_csv
 {
  position: relative;
  width: 70px;
  height: 30px;
  background: rgba(234, 236, 240, 1) !important;
}

.drop_zone img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1;
}

.drop_zone button {
  margin: 0;
  color: #fff;
  background: #16a085;
  border: none;
  margin-top: -20px;
  margin-left: -4px;
  border-radius: 4px;
  border-bottom: 4px solid #117a60;
  transition: all 0.2s ease;
  outline: none;
}

.drop_zone button:hover {
  background: #149174;
  color: #0c5645;
}

.drop_zone button:active {
  border: 0;
}
.change_csv_container.pdf_docs .change_csv_wrap.btn {
  background: rgba(234, 236, 240, 1) !important;
  width: 60px;
  height: 25px;
} 
.proof_popup {
  overflow: auto;
}

.proof_popup .docs_supported_popup img {
  width: 200px;
}
  .proof_popup .docs_supported_popup {
    z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: .2s ease-out;
  transform: translate(-15px, 0px);
}
  .proof_popup:hover .docs_supported_popup {
    opacity: 1;
    visibility: visible;
}

/* Image DropZone Styles */

.imgDrop_zone {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 12em;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  overflow: hidden;
  /* cursor: pointer; */
  background: transparent;
}

.imgDrop_zone p {
  text-align: center;
  /* line-height: 100px; */
  color: #667085;
  /* font-family: Arial; */
}

.imgDrop_zone p span {
  font-weight: 500;
  font-size: 14px !important;
}

.imgDrop_zone input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.dz_background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

.change_portrait_container .change_portrait {
  position: relative;
  width: 100px;
  height: 30px;
  opacity: 0;
  cursor: pointer;
}
.change_portrait_container .change_portrait_wrap {
  background: rgba(229, 231, 235, 1);
}

.image_optional img {
  transform: scaleY(1.2)
}

  .imgDrop_zone .preview_selImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 9px 7px;
  overflow: hidden;
  border-radius: 6px;
}

.imgDrop_zone .preview_selImage.paylink {
  border-radius: 15px !important;
}

.imgDrop_zone img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 6px !important;
  aspect-ratio: 1;
}

.imgDrop_zone button {
  margin: 0;
  color: #fff;
  background: #16a085;
  border: none;
  /* height: 35px; */
  margin-top: -20px;
  margin-left: -4px;
  border-radius: 4px;
  border-bottom: 4px solid #117a60;
  transition: all 0.2s ease;
  outline: none;
}

.imgDrop_zone button:hover {
  background: #149174;
  color: #0c5645;
}

.imgDrop_zone button:active {
  border: 0;
}

.overlay_div {
  visibility: hidden;
  opacity: 0;
  transition: .3s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  filter: blur(3px);
  transition: .3s ease;
  z-index: 2000 !important;
}

.overlay_div.show_overlay {
  visibility: visible;
  opacity: 1;
}

.inputs_n_label {
  position: relative !important;
}

.inputs_n_label .icon {
  cursor: pointer;
  position: absolute;
  top: -4px;
  right: 0;
  /* border: 1px solid red; */
  padding: 12px;
}
.white_circular_progress span.MuiCircularProgress-root {
  position: absolute !important;
  top: 16px;
  right: 20px;
  /* transform: translateY(-50%); */
}
.white_circular_progress svg,
.white_circular_progress svg circle
 {
 color: #fff !important;
}

.dropdown-menu.show {
  z-index: 99999 !important;
}

.dropdown-menu.sideNav_dropdown {
  transform: translate3d(15px, 87px, 0px) !important;
  min-width: 220px;
}
.dropdown-menu.sideNav_dropdown .top_side {
  border: none;
  border-bottom: 1px solid rgba(243, 244, 246, 1);
}

/* .MuiList-root {
  z-index: 99999 !important;
} */
.MuiPopover-root.MuiMenu-root {
  position: fixed;
  z-index: 99999 !important;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}

.font_10 {
  font-size: 10px !important;
}

.font_11 {
  font-size: 11px !important;
}

.font_12 {
  font-size: 12px !important;
}

.font_13 {
  font-size: 13px !important;
}

.font_14 {
  font-size: 14px !important;
}

.font_15 {
  font-size: 15px !important;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden !important;
  opacity: 0 !important;
  font-size: 0 !important;
  /* Make the text invisible */
}

input[type="file"]::-moz-file-upload-button {
  visibility: hidden !important;
  opacity: 0 !important;
  font-size: 0 !important;
  /* Make the text invisible */
}

input[type="file"]::file-selector-button {
  visibility: hidden !important;
  opacity: 0 !important;
  font-size: 0 !important;
  /* Make the text invisible */
}

/* input[type="file"] {
  border: 2px solid red !important;
} */

.advanced_opt.dropdown-toggle {
  border-radius: 20px !important;
}

.advanced_opt.dropdown-toggle~.dropdown-menu a {
  font-size: 13px !important;
  padding: .25rem 1rem;
}

.modals_head_title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: rgba(17, 24, 39, 1);
}

.create_wallet_select_curr .MuiSelect-select {
  padding-left: 46px !important;
  padding-top: 13px !important;
}

.create_wallet_select_curr.small .MuiSelect-select {
  padding-left: 35px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
}
.create_wallet_select_curr.small .MuiSelect-select,
.create_wallet_select_curr.small input,
.create_wallet_select_curr.small fieldset {
  border: none !important;
}

.receiving_wallet_field .MuiSelect-select {
  padding-left: 40px !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
  background: transparent !important;
}

.singlewallet_tooltip {

}

.wallet_designed_tabs_wrap .tab-pane.fade {
  transition: none !important;
  animation: none !important;
}

.wallet_designed_tabs_wrap .tab-pane:not(.active.show) {
  display: none !important;
}

/* .wallet_designed_tabs_wrap .tab_animated_indicator {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(249, 250, 251, 1) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  z-index: -1 !important;
  border-radius: .25rem;
  transition: .2s ease-out;
  width: 112px;
  height: 49px;
} */

.wallet_designed_tabs_wrap .nav-item:nth-of-type(1) .nav-link.active .tab_animated_indicator {
  transform: translateX(0%);
}

.wallet_designed_tabs_wrap .nav-item:nth-of-type(2) .nav-link.active .tab_animated_indicator {
  transform: translateX(33.3%);
}

.wallet_designed_tabs_wrap .nav-item:nth-of-type(3) .nav-link.active .tab_animated_indicator {
  transform: translateX(66.6%);
}


.wallet_designed_tabs_wrap .nav-pills {
  background-color: rgba(243, 244, 246, 1);
  border-radius: 10px;
  padding: 6px 9px;
  gap: .8em;
}

.wallet_designed_tabs_wrap .nav-link {
  background-color: transparent;
}

.wallet_designed_tabs_wrap .nav-link.active {
  background-color: transparent !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  background-color: rgba(249, 250, 251, 1) !important;

}

.wallet_designed_tabs_wrap .nav-link .ngn_text {
  font-size: 16px;
  font-weight: 700;
  color: rgba(156, 163, 175, 1);
}

.wallet_designed_tabs_wrap .nav-link.active .ngn_text {
  color: rgba(17, 24, 39, 1);
}

.wallet_designed_tabs_wrap .designed_tab_content_inner_wrapper {
  min-width: 200px;
  color: rgba(17, 24, 39, 1);
}

.wallet_designed_tabs_wrap .designed_tab_content_inner p {
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  color: rgba(17, 24, 39, 1);
}

.wallet_designed_tabs_wrap .designed_tab_content_inner h3 {
  font-size: 35px;
  font-weight: 700;
  text-align: right;
  color: rgba(17, 24, 39, 1);
}

.wallet_designed_tabs_wrap .designed_tab_content_inner h3 span {
  font-size: 35px;
  font-weight: 600;
  color: rgba(17, 24, 39, 1);
}

.wallet_designed_tabs_wrap .action_buttons {
  gap: 5px;
}

.wallet_designed_tabs_wrap .action_buttons button {
  box-shadow: 0px 1.15px 2.3px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(107, 114, 128, 1) !important;
  background: rgba(255, 255, 255, 1);
  padding: 10.33px 0px 10.33px 0px;
  width: 140px;
  font-size: 14px !important;
  font-weight: 500;
  vertical-align: middle;
  white-space: nowrap;
}

.wallet_designed_tabs_wrap .action_buttons button span {
  vertical-align: top;
}

.wallet_designed_tabs_wrap.invoice .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
  .wallet_designed_tabs_wrap.invoice .nav-link.active {
  background-color: transparent !important;
  box-shadow: none;
}

.wallet_designed_tabs_wrap.invoice .nav-pills {
  background-color: rgba(243, 244, 246, 1);
  border-radius: 10px;
  padding: 2px 12px 3px;
  gap: .8em;
}

.wallet_designed_tabs_wrap.invoice .nav-item:nth-of-type(1) .nav-link:not(.active) .inactive_svg,
.wallet_designed_tabs_wrap.invoice .nav-item:nth-of-type(2) .nav-link:not(.active) .inactive_svg,
.wallet_designed_tabs_wrap.invoice .nav-item:nth-of-type(1) .nav-link.active .active_svg,
.wallet_designed_tabs_wrap.invoice .nav-item:nth-of-type(2) .nav-link.active .active_svg
{
display: block;
}
  .wallet_designed_tabs_wrap.invoice .nav-item:nth-of-type(1) .nav-link:not(.active) .active_svg,
  .wallet_designed_tabs_wrap.invoice .nav-item:nth-of-type(2) .nav-link:not(.active) .active_svg,
  .wallet_designed_tabs_wrap.invoice .nav-item:nth-of-type(1) .nav-link.active .inactive_svg,
  .wallet_designed_tabs_wrap.invoice .nav-item:nth-of-type(2) .nav-link.active .inactive_svg
  {
display: none;
}

.wallet_designed_table_wrap .top_wrap h4 {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.wallet_designed_table_wrap .top_wrap a {
  text-decoration: underline !important;
  text-underline-offset: 3px;
  font-size: 14px;
}


/*================= Badge Stylings =================*/

.badge_style {
  padding: 2px 10px 2px 8px;
  border-radius: 4px;
}

.badge_style span {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.badge_style.success,
.badge_style.SUCCESS,
.badge_style.WON,
.badge_style.posted,
.badge_style.paid,
.badge_style.PAID,
.badge_style.POSTED,
.badge_style.successful,
.badge_style.SUCCESSFUL,
.badge_style.completed,
.badge_style.COMPLETED,
.badge_style.approved,
.badge_style.APPROVED,
.badge_style.receivables,
.badge_style.RECEIVABLES,
.badge_style.PRIMARY,
.badge_style.primary
{
  padding-top: 3px;
  padding-bottom: 3px;
  background: rgba(209, 250, 229, 1) !important;
  color: rgba(6, 95, 70, 1) !important;
}
.badge_style.success .dot,
.badge_style.SUCCESS .dot,
.badge_style.WON .dot,
.badge_style.posted .dot,
.badge_style.paid .dot,
.badge_style.PAID .dot,
.badge_style.POSTED .dot,
.badge_style.successful .dot,
.badge_style.SUCCESSFUL .dot,
.badge_style.completed .dot,
.badge_style.COMPLETED .dot,
.badge_style.approved .dot,
.badge_style.APPROVED .dot,
.badge_style.receivables .dot,
.badge_style.RECEIVABLES .dot,
.badge_style.PRIMARY .dot,
.badge_style.primary .dot
{
  background: rgba(52, 211, 153, 1) !important;
}

.badge_style.success span,
.badge_style.SUCCESS span,
.badge_style.success span,
.badge_style.posted span,
.badge_style.successful span,
.badge_style.successful span
 {
  color: rgba(6, 95, 70, 1);
}

.badge_style.open,
.badge_style.OPEN,
.badge_style.awaiting,
.badge_style.AWAITING,
.badge_style.unpaid,
.badge_style.UNPAID,
.badge_style.pending,
.badge_style.PENDING
{
  padding-top: 3px;
  padding-bottom: 3px;
  background: rgba(254, 243, 199, 1) !important;
  color: rgba(146, 64, 14, 1) !important;
} 

.badge_style.open .dot,
.badge_style.OPEN .dot,
.badge_style.awaiting .dot,
.badge_style.AWAITING .dot,
.badge_style.unpaid .dot,
.badge_style.UNPAID .dot,
.badge_style.pending .dot,
.badge_style.PENDING .dot
{
  background-color: #FBBF24 !important;
}


.badge_style.open span,
.badge_style.OPEN span,
.badge_style.awaiting span,
.badge_style.AWAITING span,
.badge_style.unpaid span,
.badge_style.UNPAID span,
.badge_style.pending span,
.badge_style.PENDING span
{
  color: rgba(146, 64, 14, 1);
}

.badge_style.lost,
.badge_style.LOST,
.badge_style.failed,
.badge_style.FAILED,
.badge_style.declined,
.badge_style.DECLINED,
.badge_style.payables,
.badge_style.PAYABLES,
.badge_style.disabled,
.badge_style.DISABLED
 {
  padding-top: 3px;
  padding-bottom: 3px;
  background: rgba(252, 231, 243, 1) !important;
  color: rgba(157, 23, 77, 1) !important;
}
.badge_style.lost .dot,
.badge_style.LOST .dot,
.badge_style.failed .dot,
.badge_style.FAILED .dot,
.badge_style.declined .dot,
.badge_style.DECLINED .dot,
.badge_style.payables .dot,
.badge_style.PAYABLES .dot
 {
  background-color: rgba(157, 23, 77, 1) !important;
 }

.badge_style.lost span,
.badge_style.failed span,
.badge_style.declined span {
  color: rgba(157, 23, 77, 1);
}

.badge_style.chargebacks.PENDING,
.badge_style.chargebacks.pending
{
  padding-top: 3px;
  padding-bottom: 3px;
  background: rgba(237, 233, 254, 1) !important;
  color: rgba(91, 33, 182, 1) !important;
} 
.badge_style.chargebacks.PENDING .dot,
.badge_style.chargebacks.pending .dot
{
  background-color: rgba(91, 33, 182, 1) !important;
}

.badge_style.chargebacks.REFUNDED,
.badge_style.chargebacks.refunded,
.badge_style.ENABLED,
.badge_style.enabled
{
  padding-top: 3px;
  padding-bottom: 3px;
  color: rgba(30, 64, 175, 1) !important;
  background: rgba(219, 234, 254, 1) !important;
} 
.badge_style.chargebacks.REFUNDED .dot,
.badge_style.chargebacks.refunded .dot,
.badge_style.ENABLED .dot,
.badge_style.enabled .dot
{
  background-color: rgba(30, 64, 175, 1) !important;
}

.rotate_icon {
  position: relative;
  transform: rotateX(180deg);
}

#menu-country{
  /* position: relative !important; */
  height: 360px !important;
}

.remove_MUI_bg .MuiBackdrop-root.MuiBackdrop-invisible,
#menu-country .MuiBackdrop-root.MuiBackdrop-invisible
 {
  background-color: transparent !important;
}
.css-13cymwt-control:hover {
  border-color: hsl(0, 0%, 70%);
}
.css-t3ipsp-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 70%) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none !important;
  box-sizing: border-box;
}
#react-select-2-placeholder,
#react-select-3-placeholder
 {
  padding-left: 5px !important;
}
.css-1fdsijx-ValueContainer input,
.css-hlgwow input {
  opacity: 1 !important;
  background-color: transparent !important;
  height: 28px !important;
}

/* .all_selectdropdown_icon ~ div.liveselect_noborderradius .css-13cymwt-control input
{
  opacity: 1 !important;
  background-color: transparent !important;
  height: 20px !important;
} */
 
button[aria-label="toggle password visibility"] {
  height: 40px !important;
  width: 40px !important;
}
svg[data-testid="VisibilityIcon"],
svg[data-testid="VisibilityOffIcon"]
{
  transform: scale(1.4) !important;
}

.top_alert_wrapper {
  width: 25em;
  max-width: 100%;
  position: fixed;
  top: 50px;
  right: 20px;
  z-index: 999999 !important;
  transform: translateX(800px);
  animation: slideIn .5s 0s ease-out 1;
}
.top_alert_wrapper.run {
  display: block !important;
  transform: translateX(0px);
  animation: slideIn .5s 0s ease-out 1;
}
.top_alert_wrapper .alert {
  padding: 15px 20px 10px 15px !important;
box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
0px 10px 15px -3px rgba(0, 0, 0, 0.1);

}
.top_alert_wrapper .close_banner {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.transactionChart .display-4 {
  font-size: 2.5rem;
}

.transactionChart img {
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  -user-drag: none;
}

.transactionChart .card-title {
  font-size: 1rem;
  color: #4B5563;
}

.transactionChart .busiest-day-chart .range_box {
  position: relative;
  height: 2px;
  border-radius: 5px;
  background-color: #000000;
}
.transactionChart .busiest-day-chart .range_box::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000000;
}

  .transactionChart .busiest-day-chart ul {
  padding: 0;
}

.transactionChart .busiest-day-chart li {
  font-size: 0.9rem;
  padding: .5rem 0;
}

.transactionChart .busiest-day-chart span {
  color: #000;
}
.transactionChart .top_stat_wrapper {
  background: rgba(249, 250, 251, 1);
border-radius: 8px;
}
.transaction_lineChart02 .chart02_buttons li {
 display: inline-block;
max-height: 38px;
}
.transaction_lineChart02 .chart02_buttons li.btn_inactive_indicator {
  background: rgba(249, 250, 251, 1) !important;
}
.hitmap_range_box .progress_bar {
  margin-top: 2px;
  width: 100%;
  height: 2px;
  position: relative;
  border-radius: 3px;
  background-color: rgba(156, 163, 175, 1);
}
.hitmap_range_box .progress_bar span {
  width: 50%;
  height: 100%;
  display: block;
  border-radius: 3px;
  background: #000000;
}
.revenue_growth_card rect {
  stroke: none !important;
  fill: transparent !important;
}
.revenue_growth_card .apexcharts-point-annotations {
  transform: translateY(-10px) !important;
}
.drawer_minHeight {
  min-height: 100vh;
}
.apexcharts-legend.apx-legend-position-bottom {
  /* transform: translateY(10px) !important; */
}
.Mui-disabled {
  -webkit-text-fill-color: #000 !important;
  -moz-text-fill-color: #000 !important;
  -ms-text-fill-color: #000 !important;
  -text-fill-color: #000 !important;
}
.__floater__arrow span svg polygon {
  fill: black !important;
}
.dashboard_measured_wrapper {
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.dashboard_measured_wrapper::-webkit-scrollbar,
.dashboard_measured_wrapper::-webkit-scrollbar-track
{
  appearance: none;
  display: none;
}
.dashboard_measured_wrapper svg text {
  font-weight: 500 !important;
  border: #aaa 1px solid !important;
  width: 10em !important;
}
.react-joyride__spotlight {
  height: 35px !important;
  transform: translate(10px, 10px) !important;
  width: 198px !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  border: 2px solid #000;
  /* border: 1px solid #e8ebee; */
  right: auto !important;
  left: -270px !important;
  z-index: 99999 !important;
}
.react-joyride__overlay {
  cursor: default;
  height: 100%;
  pointer-events: auto;
  inset: 0px;
  overflow: visible !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80% !important;
  margin-left: auto;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  mix-blend-mode: hard-light;
  width: 100%;
  background: #DFE2E140 !important;
  -webkit-backdrop-filter: blur(9px) !important;
  backdrop-filter: blur(9px) !important;
}
@keyframes slideIn {
  from {
    transform: translateX(800px);
  }
  to {
    transform: translateX(0px);
  }
}

.copy_tooltip {
  position: absolute;
  top: 25px;
  right: 0;
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0.75;
}
.MuiStep-root.MuiStep-horizontal,
.MuiStepLabel-root.MuiStepLabel-horizontal
{
  cursor: pointer !important;
}

.MuiStepper-root.MuiStepper-horizontal {
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: nowrap !important;
  gap: .8em;
}
.checkout_box .checkout_logo {
  width: 170px;
}
.payment_checkout .MuiTabs-flexContainer {
display: flex !important;
justify-content: space-between !important;
align-items: stretch !important;
width: 100%;
}
.payment_checkout .MuiTabs-flexContainer button svg {
  /* padding-bottom: 3px !important; */
}
  .payment_checkout .MuiTabs-flexContainer button {
  width: 31.5% !important;
  border: 1px solid #E5E7EB !important;
  border-radius: 8px;
  text-align: left !important;
  padding-top: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  display: flex !important;
  justify-content: flex-end !important;
align-items: flex-start !important;
font-weight: 500;
color: #9CA3AF !important;
/* gap: 5px; */
}
.payment_checkout .MuiTabs-flexContainer button.Mui-selected {
  box-shadow: inset 0 0 0 1px #000 !important;
  border: 1px solid #000 !important;
color: #000 !important;
background: #F9FAFB;

}
.payment_checkout .MuiTabs-flexContainer button.Mui-selected svg path {
fill: #000 !important;
}

.payment_checkout .MuiTabs-indicator {
  display: none !important;
}

.payment_checkout_wrapper div[role="combobox"] *,
.payment_checkout_wrapper label~div * {
  border-radius: 8px !important;
  font-size: 13px !important;
}

.dial_code .MuiInputBase-root .Mui-disabled,
.dial_code .MuiFormControl-root,
.disabled_bg .MuiInputBase-root .Mui-disabled,
.disabled_bgoverlay,

.liveselect_noborderradius div[aria-disabled="true"]
  {
  /* background-color: ; */
  background: #F3F4F6 !important;
}

.disabled_bg.pwIcon .MuiFormControl-root,
.disabled_bg.pwIconoverlay {
  background: #F3F4F6 !important;
}

.disabled_bg .MuiInputBase-root .Mui-disabled {
  transform: translateY(1.3px) !important;
}

.form-control:disabled {
  background-color: transparent !important;
  min-height: 39px;
  font-size: 16px !important;
}

.payment_checkout_wrapper .dial_code input {
  text-align: center !important;
  font-weight: 600;
  -webkit-text-fill-color: #6B7280 !important;
  color: #6B7280 !important;
}

.input_box {
  margin-bottom: 20px;
}

.input_box label {
  color: #374151;
  font-weight: 500;
  font-size: 14px;
}

.input_box input {
  height: 10px;
}

.input_box input::placeholder {
  font-size: 14px;
}

.input_box small {
  font-size: 14px;
}
.MuiBox-root:focus,
.MuiBox-root:focus-visible
 {
  border: none !important;
  outline: none !important;
 }
.white_circular_prog svg circle {
stroke: white;
}
.popover_selector .css-b62m3t-container, 
.popover_selector .css-13cymwt-control,
.popover_selector .css-t3ipsp-control
{
  background-color: transparent !important;  
}
/* .popover_selector .css-1dimb5e-singleValue {
  color: transparent !important;
}
.popover_selector div[role="combobox"] {
  color: transparent !important;
}

.popover_selector .MuiSelect-nativeInput {
  color: transparent !important;
}
.popover_selector .MuiPaper-root {
  width: 25em !important;
  max-width: 100% !important;
}
.popover_selector .MuiButtonBase-root {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  word-break: keep-all;
} */

.popover_el .MuiPaper-root li[role="menuitem"] {
  font-size: 13px !important;
}
.remove_calc_height::-webkit-scrollbar,
.remove_calc_height::-webkit-scrollbar-track
{
  appearance: none;
  display: none;
}

.user_account_name {
  width: 120px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  word-break: keep-all;
}

.KYC_goal {
  position: relative;
  height: auto;
  width: 100%;
  margin: 0 auto;
  background: rgba(243, 244, 246, 1);
  border-radius: 0.75em;
  overflow: visible;
}
.KYC_goal .KYC_so_far {
  position: relative;
  height: 0.55em;
  width: 0%;
  border: none;
  border-radius: 0.5em;
  background: linear-gradient(90deg, #54A988 0%, #456962 100%);
}

.horizontal_invoice_chart .apexcharts-inner.apexcharts-graphical {
 transform: translateX(17px) translateY(30px) !important;
}

.floating_chart_wrap {
  position: absolute;
  width: 92%;
  top: 27px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.floating_chart_wrap::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0 0 0 20px rgba(243, 244, 246, 1);
}

.floating_chart_wrap.rev {
  position: absolute;
  width: 90%;
  top: 27px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.PM_chart text[fill="#111827"] {
  font-weight: 400 !important;
}

.apexcharts-datalabel {
  font-weight: 400 !important;
}
text[x="341.70099999999996"],
text[x="439.30199999999996"] {
  /* color: black !important; */
  fill: black !important;
}
.weekly_volume_chart * {
  overflow: visible !important;
}
  .weekly_volume_chart .apexcharts-datalabels {
  transform: translateX(45px) !important;
  overflow-x: visible !important;
}

/* Top Countries scroll-container CSS styles */
.scroll-container {
  /* height: 300px; */
  padding: 50px 0;
  overflow-y: auto;
  position: relative;
}

.scroll-item {
  text-align: left;
  font-size: 24px;
  margin: 50px 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  color: rgba(255, 255, 255, 0.3);
}

.scroll-item.active {
  font-size: 36px;
  color: rgba(255, 255, 255, 1) !important;
}

.scroll-item #rank {
  font-size: 20px;
  margin-bottom: 5px;
  display: block;
  width: 100px;
  padding-left: 25px;
}

.scroll-item #country {
  font-size: 36px;
  font-weight: bold;
  display: block;
  line-height: 1;
}

.scroll-item #transaction {
  font-size: 18px;
  display: block;
}
.scroll-item.active #rank,
.scroll-item.active #country,
.scroll-item.active #transaction
{
  color: rgba(255, 255, 255, 1) !important;
}


/* Toast Notification Styles .css--control */
.toast-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.toast-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.toast-exit {
  opacity: 1;
  transform: translateY(0);
}
.toast-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 300ms, transform 300ms;
}


@media screen and (max-width: 768px) {

.checkout_box {
  padding: 0 15px;
}
.checkout_box .top {
  padding-left: 5px !important;
}
  .checkout_box .checkout_logo {
  width: 130px;
}
  .stepper_wrapper {
    flex-direction: column !important;
  }
.MuiStepper-root.MuiStepper-horizontal {
  /* flex-direction: inherit !important; */
  justify-content: flex-start;
  align-items: flex-start;
  white-space: nowrap !important;
  gap: .3em;
  width: 100%;
  overflow-x: auto !important;
  margin-bottom: 2em;
}
}
  @media screen and (max-width: 576px) {
    .tell_us_more {
      font-size: 18px;
    }
    .remove_calc_height {
      height: auto !important;
    }
  .d-sm-block.d-none {
    display: none !important;
  }
  .wallet_designed_tabs_wrap .nav-pills {
    gap: 0em;
  }

  .wallet_designed_tabs_wrap .nav-link .ngn_text {
    font-size: 14px;
  }

  .wallet_designed_tabs_wrap .nav-link {
    padding-left: 5px !important;
    padding-right: 10px !important;
  }

  .wallet_designed_tabs_wrap .designed_tab_content_inner p {
    font-size: 14px;
  }

  .wallet_designed_tabs_wrap .designed_tab_content_inner h3,
  .wallet_designed_tabs_wrap .designed_tab_content_inner h3 span {
    font-size: 25px;
  }

  .wallet_designed_tabs_wrap .action_buttons button {
    width: 100%;
  }


  .image_optional img {
    /* min-height: 295px; */
    transform: scaleY(1.2) !important;
  }
  .input_fullWidth .react-datepicker-wrapper {
    width: 100% !important;
  }
  .input_fullWidth {
    width: 100% !important;
  }
  .chartheight_specified {
    min-height: auto !important;
  }

.transaction_lineChart02 .card-body {
  padding-left: 10px;
  padding-right: 10px;
}
}

@media screen and (max-width: 405px) {
  .image_optional img {
    /* min-height: 295px; */
    transform: scaleY(1.7) !important;
  }

  .wallet_designed_tabs_wrap .designed_tab_content_inner h3,
  .wallet_designed_tabs_wrap .designed_tab_content_inner h3 span {
    font-size: 20px;
  }

  .wallet_designed_tabs_wrap .designed_tab_content_inner p,
  .wallet_designed_tabs_wrap .designed_tab_content_inner h3 {
    text-align: left !important;
  }
}

@media screen and (max-width: 460px) {
  .wallet_page_head_section p {
    width: 230px;
    max-width: 100%;
  }

  .form-control {
    width: 100% !important;
  }

  .wallet_designed_tabs_wrap .designed_tab_content_inner_wrapper {
    min-width: auto;
    color: rgba(17, 24, 39, 1);
  }

  .image_optional img {
    /* min-height: 295px; */
    transform: scaleY(1.3) !important;
  }
}

@media screen and (max-width: 320px) {
  .image_optional img {
    transform: scaleY(2) !important;
  }
}
@media (max-width: 440px) {
.last_btn_arrg {
  margin-top: .5rem;
}
}
@media (min-width: 440px) {
  .last_btn_arrg {
      margin-left: .5rem !important;
  }
}