
.overflow_table {
    width: 100%;
  }


  .table {
    width: 100% !important;
  }


  .thead th {
    color: #6b7280;
    border-bottom: 1px solid #eaecf0 ;
    font-weight: 500;
    font-size: 11px;
    padding: 16px;
    padding-left: 23px;
    padding-right: 9px;
  }
  
  .tr {
    height: 60px;
    /* border-bottom: 1px solid #eaecf0; */
  }
  .thead tr {
    height: 44px;
  }
  
  tbody tr {
    /* height: 44px; */
    background: rgba(107, 114, 128, 0.05) !important;
  }
  .tr td {
    font-style: normal;
    font-weight: 400;
    font-size: 12.70px;
    padding: 1px;
    color: #6b7280;
    /* border-bottom: 1px solid #eaecf0; */
    padding-left: 23px;
    padding-right: 9px;
  }
  .thead_flat, .thead_flat tr, .thead_flat th {
    height: 0 !important;
  }
  .table.notification_tab tr:hover{
    background-color: white !important;
  }

  .dropdown_style {
    top: 30px !important;
    left: 30px !important;
  }

.hold_input {
  position: relative !important;
}

.hold_input img {
  position: absolute;
  top: 13px;
  left: 12px !important;
  width: 16px;
}
.hold_input1 img {
  position: absolute;
  top: 24%;
  left: 10px !important;
}
.hold_input input {
  background: #FFFFFF !important;
  border: 1px solid rgba(107, 114, 128, 0.1) !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 0px !important;
}

.header_wrap {
  border-bottom: 1px solid lightgray;
  padding-bottom: .8em;
}
.signin_form_wrap {
  width: 31em;
  max-width: 100%;
}
.signin_form_wrap input, 
.signin_form_wrap input:active, 
.signin_form_wrap input:focus {
    border: none !important;
    outline: none !important;
}
.input_box {
    margin-bottom: 20px;
}

.input_box label {
    color: #374151;
    font-weight: 500;
    font-size: 14px;
}

.input_box input {
    height: 10px;
}

.input_box input::placeholder {
    font-size: 14px;
}

.input_box small {
    font-size: 14px;
}

@media screen and (max-width: 576px) {
  .header_wrap {
      border-bottom: 1px solid lightgray;
      padding-bottom: .5em !important;
  }
}