.trfpage_head h2 span {
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    text-align: center;
    color: #111827 !important;

}


.trfpage_cardbox {
    width: 45em;
    max-width: 100%;
    /* margin: auto; */
}

.trfpage_cardbox2 {
    max-width: 100%;
    /* margin: auto; */
}

.trfpage_cardbox h6 {
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    color: #000000;
}

.trfpage_cardbox p {
    font-size: 16px;
    font-weight: 500;
    line-height: 17.6px;
    text-align: center;

    color: #111827;
}

.trfpage_cardbox button {
    padding: 13px;
}

.trfpage_cardbox button span {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 23.2px;
    letter-spacing: 0.5px;
    text-align: center;

}

.overflow_table {
    width: 100%;
}

.table {
    width: 100% !important;
}

.table thead tr th:last-child,
.table thead tr td:last-child {
    width: fit-content !important;
}

.thead th {
    color: #6B7280 !important;
    border-bottom: 1px solid #EAECF0;
    font-weight: 500;
    font-size: 11px;
    padding: 16px;
    padding-left: 23px;
    padding-right: 9px;
}


.tr {
    height: 55px !important;
    border-bottom: none;
    border-top: none;
}

.thead tr {
    height: 44px;
}

tbody tr {
    height: 44px;
    /* background: rgba(107, 114, 128, .05) !important; */
}

.tr td {
    font-style: normal;
    font-weight: 400;
    font-size: 12.70px;
    padding: 1px;
    color: #6B7280;
    border-bottom: none;
    padding-left: 23px;
    padding-right: 9px;
}


.charges_input {
    position: relative;
    width: 100%;
}

.charges_input span {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
}

.charges_input * {
    box-shadow: none !important;
    background-color: transparent !important;
}

.select_wrap {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 35px;
    margin-left: auto;
    overflow: hidden;
}

.select_wrap div[role="combobox"] {
    padding-left: 10px !important;
    padding-right: 0 !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(55, 65, 81, 1) !important;
}

.css-z177mx-MuiSvgIcon-root-MuiSelect-icon {
    right: 0 !important;
}

.charges_input {
    position: relative;
    width: 100%;
    /* border: 2px solid blue; */
}
.charges_input.convert_side {
    background: rgba(243, 244, 246, 1) !important;
}

.charges_input input {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05) !important;
    border: 1px solid rgba(229, 231, 235, 1) !important;
    min-height: 45px;
}

.charges_input input::placeholder {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: rgba(156, 163, 175, 1);
}

.charges_input span {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
}