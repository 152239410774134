body {
    background-color: #F9FAFB;
  }
  .table-container {
    position: relative;
  }
  
  .preloaders {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F9FAFB; /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  #loaders {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: rgba(0, 0, 0, 1);
    animation: spin 2s linear infinite;
  }
  
  #loaders:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: rgba(107, 114, 128, 1);
    animation: spin 3s linear infinite;
  }
  
  #loaders:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #dee2e6;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  .preloaders {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 50px); /* Adjust according to your table header height */
    background-color: #F9FAFB; /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .preloaders.fade-out {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s ease-out;
  }
  
  #loaders {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: rgba(0, 0, 0, 1);
    animation: spin 2s linear infinite;
  }
  
  #loaders:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: rgba(107, 114, 128, 1);
    animation: spin 3s linear infinite;
  }
  
  #loaders:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #dee2e6;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  