.landing {
    /* background-image: url('../../../public/assets/images/rabbit_dash.svg'); */
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 12px;
}

.operations_side_wrap {
    width: 33.4em;
    max-width: 100%;
}

.header_wrap {
    border-bottom: 1px solid lightgray;
    padding-bottom: .8em;
}

.button_text {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}

.amount_style {
    font-weight: 500;
    font-size: 17.84px;
    line-height: 26.77px;
    color: rgba(17, 24, 39, 1);
}

.hug {
    /* width: 156.61px; */
    height: 31.03px;
    margin-left: 1em;
    opacity: 0px;
}

.trftobank_side_wrap {
    width: 26em;
    max-width: 100%;
}
.convert_form_first {
    background: rgba(249, 250, 251, 1);
border-radius: 5px;
padding: 8px 14px;
}
.convert_form_first_inner {
    background: #fff;
padding: 5px 8px 2px;
}
.convert_form_first_inner span:nth-of-type(1) {
font-size: 14px;
font-weight: 500;
line-height: 21px;
text-align: left;
transform: translateY(-3px);
}
.convert_form_first_inner span:nth-of-type(2) {
    font-size: 14px;
font-weight: 700;
line-height: 21px;
text-align: left;

transform: translateY(-3px);
}
.charges_input {
    position: relative;
    width: 100%;
    /* border: 2px solid blue; */
  }

  .charges_input span {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
  }
  .select_wrap {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translate(0%, -45%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 37px;
    margin-left: auto;
    overflow: hidden;
    /* border: 2px solid blue; */
  }

.hold_input {
    position: relative !important;
}


.hold_input img {
    position: absolute;
    top: 9px;
    left: 10px !important;
    width: 16px;
}
.hold_input1 img {
    position: absolute;
    top: 23%;
    left: 10px !important;
}
.hold_input input {
    background: #FFFFFF !important;
    border: 1px solid rgba(107, 114, 128, 0.1) !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 0px !important;
}

.requirement_wrap span {
    font-size: 13px;
    color: rgba(102, 112, 133, 1);
}
    .requirement_wrap .requirement_ul {
    list-style-position: outside;
    padding-left: 16px;
    font-size: 13px;
}
.requirement_wrap .requirement_ul li {
    color: rgba(102, 112, 133, 1);
    font-size: 13px;

}

.logo_wrap {
    display: inline-block;
    /* position: relative;
    top: 15px;
    left: 0px; */
}

.logo_wrap img {
    width: 110px !important;
    margin: 0 !important;
    display: inline-block;
}

.logo_wrap2 img {
    width: 30px !important;
    margin: 0 !important;
    display: inline-block;
}

.wallet_graph_area {
    position: relative;
    min-height: 11em;
}
.wallet_graph_area img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

}















.card2 {
    border-radius: 12px;
}
.card2 p {
    /* font-weight: bold; */
}
.wrapper .header h4 {
    font-weight: bold !important;
}

.wrapper .header p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */


    /* gray/600 */

    color: #4B5563;
}
.card {
    background: #FFFFFF !important;
    /* gray/200 */

    border: 1px solid #E5E7EB !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
}
.card span, small {
    font-weight: bold;
}
.numbers {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.numbers div small {
    font-style: normal;

    font-size: 11px;
    color: #6B7280;


}

div.percent {
    background: #D1FAE5;
    border-radius: 12px;
    padding: 0px 10px;
    display: inline-block;
    width: '67px';
    height: '25px';
    border-radius: '12px';
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    justify-content: ' space-around';

}

div.percent2 {
    background: #FEE2E2;
    border-radius: 12px;
    padding: 0px 10px;
    display: inline-block;
    width: '67px';
    height: '25px';
    border-radius: '12px';
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    color: #991B1B !important;
    justify-content: ' space-around';

}

div.percent small {
    font-size: 10px;
}

div.percent2 small {
    font-size: 10px;
    color: #991B1B !important;

}





.wallet {
    width: 100% !important;
    height: 100% !important;

    /* background-image:url('/public/assets/crc.png'); */

    /* background-repeat:no-repeat !important;
                  
    border-radius:30px !important; */

    position: relative !important;
}

.wallet .content {
    padding: 32px 41px;
    color: #FFFFFF;
    position: relative !important;
    z-index: 31 !important;
}

.discount_banner {
    background: #D97706;
    /* amber/700 */

    border: 1px solid #B45309;
    color: #EFF6FF;
    display: inline-block !important;
    width: 120px;
    text-align: center;
    position: absolute;
    right: -3%;
    transform: rotate(35deg);
    top: 5%;
    font-size: 13px;
}

.wallet .img {
    width: 100% !important;

    position: absolute !important;
    z-index: 0.1 !important;
    margin: 2px 10px !important;
    height: 224px;
}
.wallet .img1 {
    height: 215px !important;

}
.wallet .img3 {
    height: 265px !important;

}
.wallet .img1 {
    width: 100% !important;
    position: absolute !important;
    z-index: 0.1 !important;
    margin: 2px 10px !important;
    height: 264px;
}

.wallet .content .sub_text {

    color: #BFDBFE;
    font-size: 10px;
    margin-bottom: 10px;
}

.wallet .content .content_body {

    color: #BFDBFE;
    font-size: 10px;
    margin-top: 35px;

}
.content_body1 {
    margin-top: 77px !important;
}

.wallet .content .content_body2 {


    height: 65px;

}

.wallet .content .content_body .bank_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wallet .content .content_body .bank_info .name {

    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    margin-bottom: 4px !important;

}

.wallet .content .content_body .bank_info .account_number {
    font-size: 1.7em;
    letter-spacing: 2px;
}

.wallet .content .content_body .bank_info img {
    height: 30px;
}
.insight_header h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    padding-top: 10px;
    /* or 30px */


    /* gray/700 */

    color: #374151
}
.insight_header p {
    font-size: 13px;
    line-height: 150%;
    /* or 21px */


    /* gray/500 */

    color: #6B7280;
}
.export_btn {
    height: 40px;
    width: 130px;

    /* blue/600 */

    background: #222222;
    /* blue/600 */

    border: 1px solid #222222;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Base/White */

    color: #FFFFFF;
    padding-top: 0;


}
.export_btn:hover {
    color: #FFFFFF;
    font-weight: bold;


}

.wallet .content .content_header {
    display: flex;
    justify-content: space-between;
}
.content_header div {
    font-weight: bold;
}

.wallet .footer {
    /* position:absolute;
    bottom:0; */
    border-top: 1px solid #E5E7EB;
    width: 100%;
    height: 40px;
}

.wallet .footer {
    text-align: right;

}

.wallet .footer div p a {
    font-size: 12px;
    color: #54B054;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
}

.wallet .footer div p span {

    cursor: pointer;

}


.wallet .content .content_header {
    display: flex;
    justify-content: space-between;
}

.wallet .footer {
    /* position:absolute;
    bottom:0; */
    border-top: 1px solid #E5E7EB;
    width: 100%;
    height: 40px;
    padding: 12px;
}

.wallet .footer2 {
    height: auto;

}

.label_styles {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    gap: 20px;
}
.label_styles label, .label_styles input {
    cursor: pointer;
}
.modal_body .span {
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 0;
}
.modal_body .span input {
    opacity: 0;
    position: relative;
    width: fit-content;
    height: fit-content;
    z-index: 99999999 !important;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.modal_body .span label span {
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
    height: fit-content;
    padding: 0 10px;
    cursor: pointer;
    transition: .5s;
}
.modal_body .span label input:checked ~ span {
    transform: rotateX(180deg);
}
.modal_content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 82vh;
}
.modal_content::-webkit-scrollbar,
.modal_content::-webkit-scrollbar-track {
    appearance: none;
    display: none;
}
.modal_content .modal_header {
    display: flex;
    justify-content: space-between;

}
  
  .radio_input, .radio_input:checked {
    border: 2px solid green !important;
    background-color: green !important;
    color: green !important;
  }
  
.arrowSpan{
    border-radius: 5px;
    padding: 8px  !important;
    border: solid 1px #D0D5DD ;
    text-align: center;
    /* margin-top: 20px; */
    /* background-color: red; */
}
.newStyle{
    filter: brightness(50%);
    height: 12px;
    width: 12px;
    
    /* transform: rotate(90deg);
    padding: 8px 12px !important; */
}
.year_styles {
    padding: 8px 15px;
    border-radius: 5px;
    border: 1px solid #D0D5DD;
    margin: 0 5px;
}
.table {
    /* color: #6B7280 !important; */
    width: 100% !important;
}
.thead  th {
    color: #6B7280 !important;
    /* border-bottom: 1px solid #EAECF0 !important ; */
    font-weight: 500;
    font-size: 12px;
    padding: 16px;
    padding-left: 23px;
    padding-right: 9px;
}

.tr {
    height: 55px !important;
    /* border-bottom: 1px solid #EAECF0; */
}

.thead tr {
    height: 44px;
}

.tr td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 150%; */
    padding: 1px;
    color: #6B7280;
    /* border-bottom: 1px solid #EAECF0; */
    padding-left: 23px;
    padding-right: 9px;
}

.filter_wrap {
    min-width: 30em;
}

@media screen and (max-width: 576px) {
    .header_wrap {
        border-bottom: 1px solid lightgray;
        padding-bottom: .5em !important;
    }
}

@media screen and (max-width: 860px) {
    .wallet {
        width: 100% !important;
    }

    .card2 {
        width: 40% !important;

    }
}


@media screen and (max-width: 757px) {
    .wallet {
        width: 100% !important;
    }

    .card2 {
        width: 100% !important;

    }
}
@media screen and (max-width: 576px) {
    /* .drawerHolder div[tabindex="-1"] {
        width: auto !important;
    } */
    .dropped_details_wrap {
      min-width: auto;
      width: 100%;
      max-width: 100%;
    }

  .drp_dwn_menu_right_trf {
    transform: translate3d(-47%, 40px, 0px) !important;
  }
  .drp_dwn_menu_right_fees {
    transform: translate3d(-47%, 40px, 0px) !important;
  }
}
@media screen and (max-width: 420px) {

    .filter_wrap {
        min-width: 23em;
    }

  .drp_dwn_menu_right_topup {
    transform: translate3d(-215px, 40px, 0px) !important;
  }
}