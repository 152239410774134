.wrapper_before {
    position: relative;
    font-size: 14px;
}

.wrapper_before::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(209.36deg, rgba(0, 175, 82, 0.75) 0%, rgba(1, 11, 34, 0.75) 100%);
}

.wrapper_before .text_wrap {
    position: relative;
    z-index: 10;
    width: 35em;
    max-width: 95%;
    height: fit-content;
    padding: 0 0 13em;
}

.wrapper_before h1 {
    max-width: 100%;
    font-family: "Inter", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 3.5cqw;
    line-height: 120%;
    color: #FFFFFF;
    padding: 0;
}

.wrapper_before p {
    position: relative;
    z-index: 10;
    font-family: "Inter", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.5;
    color: #fff !important;
    margin-top: 1.5em;
}

.join_sect {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 25px;
}

.join_sect > div {
    white-space: nowrap;
}

.join_sect p {
    margin: 0;
    font-size: 18px;
}

.logo_wrap {
    position: absolute;
    top: 15px;
    left: 0px;
}

.logo_wrap img {
    width: 40px !important;
}

.signin_form_wrap {
    width: 31em;
    max-width: 100%;
}

.signin_form_wrap h4 {
    margin-bottom: 15px;
    color: #111827;
    /* font-weight: 500 !important; */
}

.input_box {
    margin-bottom: 20px;
}

.input_box label {
    color: #374151;
    font-weight: 500;
    font-size: 14px;
}

.input_box input {
    height: 10px;
}

.input_box input::placeholder {
    font-size: 14px;
}

.input_box small {
    font-size: 14px;
}

.signin_form_wrap input, 
.signin_form_wrap input:active, 
.signin_form_wrap input:focus {
    border: none !important;
    outline: none !important;
}
input ~ fieldset, input:active ~ fieldset, input:hover, fieldset:hover {
    /* border: 1px solid #6B7280 !important;    */
}

input:focus ~ fieldset {
    border: 1px solid #222222 !important;   
    outline: none !important; 
    box-shadow: none !important;
}

.forgot a {
    text-decoration: none;
    color: #222222;
    font-size: 14px;
    font-weight: 500;
}

.signin_btn {
    background: #bbb;
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: white !important;
    font-weight: normal !important;
    margin-bottom: 20px;
}

.signin_btn:hover {
    background: #222222;
    color: whitesmoke;
}

.signin_btn_bg {
    background: #111827 !important;
    color: #fff !important;
}

.google_btn {
    background: transparent;
    border: 1px solid #bbb !important;
    font-weight: 600 !important;
    display: block;
    width: 100%;
    padding: 3px;
    font-size: 14px;
    color: #111;   
}

.google_btn img {
    width: 40px;
    vertical-align: middle;
}

.signup_switch {
    margin: 0 auto 2em;
    color: #888;
}

.signup_switch a {
    color: #222222; 
    text-decoration: none;
    font-weight: 500;
}

.signup_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    text-align: center;
    color: #888;
    font-size: 14px;
    width: fit-content;
    margin-right: auto;
}

.signup_footer p {
    margin: 6px 0;
    width: fit-content;
}

.signup_footer a {
    color: #000000CC;
    transition: .3s;
    text-decoration: none;
    font-weight: 500;
}

.signup_footer a:hover {
    color: #222222;
}

@media screen and (max-width: 576px) {
    .signin_form_wrap {
        /* margin-top: 4em !important; */
        width: 100%;
        max-width: 100%;
    }
}