* {
    font-family: "Inter", sans-serif !important;

}

span {
    font-size: 13px;
}

body {
    font-family: "Inter", sans-serif !important;
    background-color: whitesmoke;
}

form {
    width: 100% !important;
}

input {
    background-color: white !important;
}

.card_no_input input {
    text-indent: 30px;
}

.header_wrap {
    border-bottom: 1px solid lightgray;
    padding-bottom: .8em;
}

.trftobank_side_wrap {
    width: 31em;
    max-width: 100%;
}

.createInvoice_side_wrap {
    width: 75%;
    max-width: 100%;
    margin: 0 auto;
}

/* .charges_input {
    position: relative;
    width: 100%;
  }

  .charges_input span {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
  }
  .charges_input * {
    box-shadow: none !important;
  }
  .select_wrap {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 35px;
    margin-left: auto;
    overflow: hidden;
  } */

/* .select_wrap div[role="combobox"] {
    padding-left: 6px !important;
    padding-right: 0 !important;
} */
.css-z177mx-MuiSvgIcon-root-MuiSelect-icon {
    right: 0 !important;
}

.charges_input {
    position: relative;
    width: 100%;
    /* border: 2px solid blue; */
}

.charges_input span {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
}

.select_wrap {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translate(0%, -45%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 37px;
    margin-left: auto;
    overflow: hidden;
    /* border: 2px solid blue; */
}

.select_wrap.hastemplate {
    width: fit-content;
    height: 35px;
}

.hold_input1 {
    position: relative;
}

.hold_input1 img {
    position: absolute;
    top: 10px !important;
    left: 10px !important;
    width: 15px;
}

.logo_wrap~span {
    font-size: 14px;
    font-weight: 600;
    line-height: 13.27px;
    color: #000000;
}

.PreviewInvoice_wrapper {
    width: 56em;
    max-width: 100%;
    margin-top: 4em;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-height: 90vh;
}

.PreviewInvoice_wrapper_prev {
    width: 100%;
    font-size: 8px !important;
    background-color: white;
    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1); */
}

.PreviewInvoice_wrapper_prev small,
.PreviewInvoice_wrapper_prev label {
    font-size: 12px !important;
}

.PreviewInvoice_wrapper_prev h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 26.4px;
    text-align: center;
}

.PreviewInvoice_wrapper_prev h4 span {
    font-size: 12px;
    font-weight: 500;
    line-height: 13.2px;
    text-align: center;
}

.PreviewInvoice_wrapper_prev h4~p {
    font-size: 12px;
    font-weight: 600;
    line-height: 13.2px;
    text-align: center;
    color: #6B7280;
    width: 24.5em;
    max-width: 100%;
    margin: auto;
}

.PreviewInvoice_wrapper_email {
    /* width: 24em;
    max-width: 100%; */
    background-color: white;
    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1); */
}

.desktop_width {
    width: 34em;
    max-width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px 0px;
}
.requirement_wrap {
    background-color: rgba(249, 250, 251, 1);
    border-radius: 8px;
    padding: 20px 15px;
}
.requirement_wrap span {
    font-size: 13px;
    color: rgba(102, 112, 133, 1);
}
    .requirement_wrap .requirement_ul {
    list-style-position: outside;
    padding-left: 16px;
    font-size: 13px;
}
.requirement_wrap .requirement_ul li {
    color: rgba(102, 112, 133, 1);
    font-size: 12px;
    margin-bottom: 12px;
}

@media screen and (max-width: 991px) {
    .createInvoice_side_wrap {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 576px) {

    .desktop_width {
        width: 24em;
        max-width: 100%;
    }

    .header_wrap {
        border-bottom: 1px solid lightgray;
        padding-bottom: .5em !important;
    }
}