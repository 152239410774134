
.input_box {
    margin-bottom: 20px;
}

.input_box label {
    color: #374151;
    font-weight: 500;
    font-size: 14px;
}

.input_box input {
    height: 10px;
}

.input_box input::placeholder {
    font-size: 14px;
}

.input_box small {
    font-size: 14px;
}